export const GANTI_TERJEMAHAN_ALKITAB = 'GANTI_TERJEMAHAN_ALKITAB';
export const GANTI_LAST_OPEN_ALKITAB = 'GANTI_LAST_OPEN_ALKITAB';

export const SET_AUDIO_PLAYER = 'SET_AUDIO_PLAYER';

export const SET_ACTIVE_MENU = 'SET_ACTIVE_MENU';

export const SET_DIALOG_PILIHAN_TERJEMAHAN_ALKITAB = 'SET_DIALOG_PILIHAN_TERJEMAHAN_ALKITAB';
export const SET_DIALOG_PILIHAN_KITAB_ALKITAB = 'SET_DIALOG_PILIHAN_KITAB_ALKITAB';

export const GANTI_KALENDERLITURGI_STATE = 'GANTI_KALENDERLITURGI_STATE';
export const GANTI_KALENDERLITURGI_TANGGAL = 'GANTI_KALENDERLITURGI_TANGGAL';
export const GANTI_KALENDERLITURGI_DATA = 'GANTI_KALENDERLITURGI_DATA';
export const GANTI_KALENDERLITURGI_LOADING = 'GANTI_KALENDERLITURGI_LOADING';
export const GANTI_KALENDERLITURGI_ISSHOW_DIALOGPILIHANTANGGAL = 'GANTI_KALENDERLITURGI_ISSHOW_DIALOGPILIHANTANGGAL';
export const GANTI_KALENDERLITURGI_ALL = 'GANTI_KALENDERLITURGI_ALL';
export const KALENDERLITURGI_SHOWMESSAGE = 'KALENDERLITURGI_SHOWMESSAGE';
export const GET_DATA_KALENDERLITURGI = 'GET_DATA_KALENDERLITURGI';
export const APPLY_DATA_KALENDERLITURGI = 'APPLY_DATA_KALENDERLITURGI';
