import React, { Fragment, Component } from 'react';

import Header from '../../components/Header'
import Slider from '../../components/Slider/home-one'
import About from '../../components/About/home-one'
import Features from '../../components/Features'
import Services from '../../components/Services'
import Team from "../../components/Team/home-one";
import Blog from "../../components/Blog";
import BrandLogo from "../../components/BrandLogo";
import Funfact from "../../components/Funfact";
import CallToAction from "../../components/CallToAction";
import Footer from "../../components/Footer";
import Testimonials from "../../components/Testimonials/home-one";
import LoginRegister from "../../components/LoginRegister";
import MobileMenu from "../../components/MobileMenu";

import { net } from '../../utils/Networking'

import { connect } from 'react-redux';
import moment from 'moment';
import { setActiveMenu } from '../../actions';

class Beranda extends Component {
    constructor(props) {
        super(props)
        this.state = {
            url_galeri_hari_ini: null,
            datakalit: null,
            dataperayaanhariini: null,
        }
    }
    componentDidMount() {
        this.props.setActiveMenu("Beranda");
        const params = new Object()

        params.pengumuman_popup = moment()

        net.post('webapp/init_app', params)
            .then(response => {
                // let data = response.data //.replace(/class/g, 'className');
                const { beranda } = response.data
                let url_galeri_hari_ini = null;
                let datakalit = null;
                let dataperayaanhariini = null;
                beranda.forEach(item => {
                    if (item.tipe === "GaleriHariIni") {
                        url_galeri_hari_ini = item.data.url_gambar
                    } else if (item.tipe === "KalenderLiturgiHariIni") {
                        datakalit = item.data
                    } else if (item.tipe === "PerayaanHariIni") {
                        dataperayaanhariini = item.data
                    }
                })
                this.setState({ url_galeri_hari_ini, datakalit, dataperayaanhariini })
            });
    }
    render() {
        return (
            <Fragment>
                <Header />
                <About
                    url_galeri_hari_ini={this.state.url_galeri_hari_ini}
                    datakalit={this.state.datakalit}
                    dataperayaanhariini={this.state.dataperayaanhariini} />
                <Testimonials />
                <CallToAction />
                <Footer />
                <MobileMenu />
                <LoginRegister />
            </Fragment>
        );
    }
};

const mapStateToProps = state => {
    return { settingState: state.settingState }
};

export default connect(mapStateToProps, { setActiveMenu })(Beranda);