// import { getUniqueId, getManufacturer } from 'react-native-device-info';

//const serverUrl = Config.SERVER_URL + ':' + Config.SERVER_PORT + '/'
// const serverUrl = 'http://localhost:3000/'
// const serverUrl = 'https://ekatolikapp.infinitlab.id:7777/'
// const serverUrl = 'https://ekatolikapi.infinitlab.id:7777/'
// const serverUrl = 'http://192.168.1.28:7890/'
const serverUrl = 'https://ruby.infinitlab.id:7777/';

const appconfig = {
    appName: 'eKatolik',
    appVersion: '1.0',
    OS: 'android',
    clientVersion: 7, // kalau ganti ini jangan lupa tambahkan row di database init setting
    apiUrl: serverUrl,
    defaultAvatar: serverUrl + 'images/avatar.jpg',
    deviceId: 'EKATOLIKWEB',
    buildNo: '4.4.5',
    alkitabSuaraServer: 'https://alkitabsuara.infinitlab.id:9877/AlkitabSuara-',
    alkitabSuaraAnakServer: 'https://alkitabsuara.infinitlab.id:9877/AlkitabSuaraAnak/',
};

export default appconfig;
