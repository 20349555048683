import React from 'react';
import {
    SET_AUDIO_PLAYER,
} from '../actions/types';

const INITIAL_STATE = {
    audioPlayerRef: React.createRef(),
    audioPlayerURL: null,
    audioPlayerJudul: '',
    audioPlayerKeterangan: 'Loading...',
    audioPlayerImage: '',
    audioPlayerLoading: false,
    audioPlayerState: 0,
    audioPlayerPlaying: false,
    audioPlayerDuration: 0,
    audioPlayerCurrentTime: 0,
    audioPlayerPlayableDuration: 0,
    audioPlayerSeekableDuration: 0,
}

export default (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case SET_AUDIO_PLAYER:
            return {
                ...state,
                ...action.payload,
            }
        default:
            return state;
    }
};
