import React, { Component, Fragment } from 'react';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { Accordion, Card } from 'react-bootstrap';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PlayCircleOutline from '@material-ui/icons/PlayCircleOutline';
import ExpandLess from '@material-ui/icons/ExpandLess';
import './index.css';

import Header from "../../components/Header";
import CallToAction from "../../components/CallToAction";
import Footer from "../../components/Footer";
import LoginRegister from "../../components/LoginRegister";
import MobileMenu from "../../components/MobileMenu";

import { connect } from 'react-redux';
import {
    setActiveMenu,
    gantiKalenderLiturgiState,
    setAudioPlayer,
    getDataKalenderLiturgi,
} from '../../actions';
import { net } from '../../utils/Networking';
import moment from 'moment';

import Calendar from 'react-calendar';

import 'react-calendar/dist/Calendar.css';

const Spinner = require('react-spinkit');
const DAY_LABELS = ['Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu', 'Minggu']
const MONTH_LABELS = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember']


function CustomToggle({ children, eventKey, data, setAudioPlayer }) {
    const decoratedOnClick = useAccordionButton(eventKey
    );
    return (
        <div
            type="div"
            className="expand-button"
            style={{ backgroundColor: 'transparent', borderWidth: 1 }}
            onClick={decoratedOnClick}
        >
            <span className="expand-button-span">
                <ExpandMoreIcon style={{ color: '#657687', marginRight: 8 }} />
                {children}
            </span>
            {
                data.audioURL !== undefined && <PlayCircleOutline onClick={() => {
                    setAudioPlayer({
                        audioPlayerJudul: data.judul,
                        audioPlayerKeterangan: data.keterangan,
                        audioPlayerImage: data.image,
                        audioPlayerURL: data.audioURL,

                    })
                }} className="expand-button-span" style={{ color: '#657687', fontSize: 30 }} />
            }
        </div>
    );
}

class KalenderLiturgi extends Component {
    constructor(props) {
        super(props);

        let tanggal = moment().format('YYYY-MM-DD');

        if (props.match.params && props.match.params.tanggal) {
            tanggal = moment(parseInt(props.match.params.tanggal)).format('YYYY-MM-DD')
        }

        props.gantiKalenderLiturgiState({
            tanggal
        })

        this.state = {
            loading: true,
            msg: 'Loading...',
            tanggal,
            ket_minggu: '',
            peringatan: '',
            bacaan_pertama: '',
            judul_bacaan_pertama: '',
            teks_bacaan_pertama: '',
            mazmur_tanggapan: '',
            mazmur_refren: '',
            mazmur_teks_refren: '',
            teks_mazmur: '',
            bacaan_kedua: '',
            judul_bacaan_kedua: '',
            teks_bacaan_kedua: '',
            bait_pengantar_injil: '',
            teks_pengantar_injil: '',
            bacaan_injil: '',
            judul_bacaan_injil: '',
            teks_bacaan_injil: '',
            warna: '',
            audioURLBacaanPertama: '',
            audioURLMazmurTanggapan: '',
            audioURLBacaanKedua: '',
            audioURLBaitPengantarInjil: '',
            audioURLBacaanInjil: '',
        }

        this.getData = this.getData.bind(this);
    }

    getData(tanggal) {
        net.post('webapp/get_kalender_liturgi', {
            tanggal
        })
            .then(response => {
                const { status_code, data_kalit, data_kalit_audio, msg } = response.data;
                if (status_code === 1) {
                    window.history.pushState('', 'Kalender Liturgi', '/kalenderliturgi/' + moment(data_kalit.tanggal).format('x'));

                    this.props.gantiKalenderLiturgiState({
                        loading: false,
                        msg: 'Loading...',
                        isShow_DialogPilihanTanggal: false,
                        data: {
                            tanggal: moment(data_kalit.tanggal).format('YYYY-MM-DD'),
                            ket_minggu: data_kalit.ket_minggu,
                            peringatan: data_kalit.peringatan,
                            bacaan_pertama: data_kalit.bacaan_pertama,
                            judul_bacaan_pertama: data_kalit.judul_bacaan_pertama,
                            teks_bacaan_pertama: data_kalit.teks_bacaan_pertama,
                            mazmur_tanggapan: data_kalit.mazmur_tanggapan,
                            mazmur_refren: data_kalit.mazmur_refren,
                            mazmur_teks_refren: data_kalit.mazmur_teks_refren,
                            teks_mazmur: data_kalit.teks_mazmur,
                            bacaan_kedua: data_kalit.bacaan_kedua,
                            judul_bacaan_kedua: data_kalit.judul_bacaan_kedua,
                            teks_bacaan_kedua: data_kalit.teks_bacaan_kedua,
                            bait_pengantar_injil: data_kalit.bait_pengantar_injil,
                            teks_pengantar_injil: data_kalit.teks_pengantar_injil,
                            bacaan_injil: data_kalit.bacaan_injil,
                            judul_bacaan_injil: data_kalit.judul_bacaan_injil,
                            teks_bacaan_injil: data_kalit.teks_bacaan_injil,
                            warna: data_kalit.warna,
                            audioURLBacaanPertama: data_kalit_audio ? data_kalit_audio.bacaan_pertama : undefined,
                            audioURLMazmurTanggapan: data_kalit_audio ? data_kalit_audio.mazmur_tanggapan : undefined,
                            audioURLBacaanKedua: data_kalit_audio ? data_kalit_audio.bacaan_kedua : undefined,
                            audioURLBaitPengantarInjil: data_kalit_audio ? data_kalit_audio.bait_pengantar_injil : undefined,
                            audioURLBacaanInjil: data_kalit_audio ? data_kalit_audio.bacaan_injil : undefined,
                        }
                    })
                } else {
                    this.props.gantiKalenderLiturgiState({
                        loading: true,
                        isShow_DialogPilihanTanggal: false,
                        msg
                    })
                }
            });
    }

    componentDidMount() {
        this.props.setActiveMenu("KalenderLiturgi");
        setTimeout(() => {
            this.props.getDataKalenderLiturgi(this.props.kalenderliturgiState.tanggal);
        }, 500)
    }

    render() {
        return (
            <Fragment>
                <Header />
                <div className="container">
                    {
                        (!this.props.kalenderliturgiState.loading)
                            ?
                            <div id="reader" className="light">

                                <h2>Bacaan Liturgi {moment(this.props.kalenderliturgiState.data.tanggal).format('DD MMM YYYY')}</h2>
                                <p style={{ lineHeight: 2.4 }}>
                                    {this.props.kalenderliturgiState.data.ket_minggu}<br />
                                    Warna Liturgi: {this.props.kalenderliturgiState.data.warna}<br />
                                    Bacaan I: {this.props.kalenderliturgiState.data.bacaan_pertama}<br />
                                    Mazmur Tanggapan: {this.props.kalenderliturgiState.data.mazmur_tanggapan}<br />
                                    {
                                        this.props.kalenderliturgiState.data.bacaan_kedua
                                            ?
                                            <span>{'Bacaan II: '} {this.props.kalenderliturgiState.data.bacaan_kedua} <br /> </span>
                                            :
                                            ''
                                    }
                                    {
                                        this.props.kalenderliturgiState.data.bait_pengantar_injil
                                            ?
                                            <span> {'Bait Pengantar Injil: '} {this.props.kalenderliturgiState.data.bait_pengantar_injil} <br /> </span>
                                            :
                                            ''
                                    }
                                    Bacaan Injil: {this.props.kalenderliturgiState.data.bacaan_injil}</p>
                                <Accordion>
                                    <div className="expand-div">
                                        <CustomToggle eventKey="0"
                                            setAudioPlayer={this.props.setAudioPlayer}
                                            data={{
                                                judul: "Bacaan I",
                                                keterangan: this.props.kalenderliturgiState.data.bacaan_pertama,
                                                image: '',
                                                audioURL: this.props.kalenderliturgiState.data.audioURLBacaanPertama
                                            }}>Bacaan I</CustomToggle>
                                        <Accordion.Collapse eventKey="0">
                                            <Card.Body style={{ margin: 16, marginTop: 0, }}>
                                                <p>{this.props.kalenderliturgiState.data.bacaan_pertama}</p>
                                                <q className="judul-bacaan-kalender-liturgi">{this.props.kalenderliturgiState.data.judul_bacaan_pertama}</q>
                                                <div className="display-linebreak">
                                                    <br />
                                                    {this.props.kalenderliturgiState.data.teks_bacaan_pertama}
                                                </div>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </div>
                                    <div className="expand-div">
                                        <CustomToggle eventKey="1"
                                            setAudioPlayer={this.props.setAudioPlayer}
                                            data={{
                                                judul: "Mazmur Tanggapan",
                                                keterangan: this.props.kalenderliturgiState.data.mazmur_tanggapan,
                                                image: '',
                                                audioURL: this.props.kalenderliturgiState.data.audioURLMazmurTanggapan
                                            }}>Mazmur Tanggapan</CustomToggle>
                                        <Accordion.Collapse eventKey="1">
                                            <Card.Body style={{ margin: 16, marginTop: 0, }}>
                                                <p>{this.props.kalenderliturgiState.data.mazmur_tanggapan}</p>
                                                <q className="judul-bacaan-kalender-liturgi">{this.props.kalenderliturgiState.data.mazmur_teks_refren}</q>
                                                <div className="display-linebreak">
                                                    <br />
                                                    {this.props.kalenderliturgiState.data.teks_mazmur}
                                                </div>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </div>
                                    {
                                        (this.props.kalenderliturgiState.data.bacaan_kedua !== '')
                                            ?
                                            <div className="expand-div">
                                                <CustomToggle eventKey="2"
                                                    setAudioPlayer={this.props.setAudioPlayer}
                                                    data={{
                                                        judul: "Bacaan II",
                                                        keterangan: this.props.kalenderliturgiState.data.bacaan_kedua,
                                                        image: '',
                                                        audioURL: this.props.kalenderliturgiState.data.audioURLBacaanKedua
                                                    }}>Bacaan II</CustomToggle>
                                                <Accordion.Collapse eventKey="2">
                                                    <Card.Body style={{ margin: 16, marginTop: 0, }}>
                                                        <p>{this.props.kalenderliturgiState.data.bacaan_kedua}</p>
                                                        <q className="judul-bacaan-kalender-liturgi">{this.props.kalenderliturgiState.data.judul_bacaan_kedua}</q>
                                                        <div className="display-linebreak">
                                                            <br />
                                                            {this.props.kalenderliturgiState.data.teks_bacaan_kedua}
                                                        </div>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </div>
                                            :
                                            null
                                    }
                                    {
                                        (this.props.kalenderliturgiState.data.bait_pengantar_injil !== '')
                                            ?
                                            <div className="expand-div">
                                                <CustomToggle eventKey="3"
                                                    setAudioPlayer={this.props.setAudioPlayer}
                                                    data={{
                                                        judul: "Bait Pengantar Injil",
                                                        keterangan: this.props.kalenderliturgiState.data.bait_pengantar_injil,
                                                        image: '',
                                                        audioURL: this.props.kalenderliturgiState.data.audioURLBaitPengantarInjil
                                                    }}>Bait Pengantar Injil</CustomToggle>
                                                <Accordion.Collapse eventKey="3">
                                                    <Card.Body style={{ margin: 16, marginTop: 0, }}>
                                                        <p>{this.props.kalenderliturgiState.data.bait_pengantar_injil}</p>
                                                        <div className="display-linebreak">
                                                            {this.props.kalenderliturgiState.data.teks_pengantar_injil}
                                                        </div>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </div>
                                            :
                                            null
                                    }
                                    <div className="expand-div">
                                        <CustomToggle eventKey="4"
                                            setAudioPlayer={this.props.setAudioPlayer}
                                            data={{
                                                judul: "Bacaan Injil",
                                                keterangan: this.props.kalenderliturgiState.data.bacaan_injil,
                                                image: '',
                                                audioURL: this.props.kalenderliturgiState.data.audioURLBacaanInjil
                                            }}>Bacaan Injil</CustomToggle>
                                        <Accordion.Collapse eventKey="4">
                                            <Card.Body style={{ margin: 16, marginTop: 0, }}>
                                                <p>{this.props.kalenderliturgiState.data.bacaan_injil}</p>
                                                <q className="judul-bacaan-kalender-liturgi">{this.props.kalenderliturgiState.data.judul_bacaan_injil}</q>
                                                <div className="display-linebreak">
                                                    <br />
                                                    {this.props.kalenderliturgiState.data.teks_bacaan_injil}
                                                </div>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </div>
                                </Accordion>
                            </div >
                            :
                            (this.props.kalenderliturgiState.isShow_DialogPilihanTanggal)
                                ?
                                <div id="reader" className="kalenderliturgi-kalender">
                                    {/* <Calendar
                                        range={false}
                                        monthLabels={MONTH_LABELS}
                                        dayLabels={DAY_LABELS}
                                        startDate={moment().toDate().getTime()}
                                        disableDates={date => date < moment().subtract(1, 'days').toDate().getTime()}
                                        onChange={(startDate, endDate) => {
                                            this.props.getDataKalenderLiturgi(moment(endDate).format('YYYY-MM-DD'));
                                        }}
                                    /> */}
                                    <Calendar
                                        minDate={new Date()}
                                        locale={"id-ID"}
                                        onChange={(value, event) => {
                                            // console.log(value)
                                            this.props.getDataKalenderLiturgi(moment(value).format('YYYY-MM-DD'));
                                        }}
                                        value={moment(this.props.kalenderliturgiState.data.tanggal).toDate()} />
                                </div>
                                :
                                <div id="reader" className="light kalenderliturgi-kalender">
                                    <Spinner style={{ margin: 8 }} name='double-bounce' />
                                    <p>{this.props.kalenderliturgiState.msg}</p>
                                </div>
                    }


                </div >
                <CallToAction />
                <Footer />
                <LoginRegister />
                <MobileMenu />
            </Fragment >
        )
    }
}

const mapStateToProps = state => {
    return {
        settingState: state.settingState,
        kalenderliturgiState: state.kalenderliturgiState
    }
};

export default connect(mapStateToProps, {
    setActiveMenu,
    gantiKalenderLiturgiState,
    setAudioPlayer,
    getDataKalenderLiturgi,
})(KalenderLiturgi);