import $ from 'jquery';
var tinycolor = require("tinycolor2");

var hiddenVerses = [];
var textRGB = "0,0,0";
var disableVerseClick;

var last_known_scroll_position = 0;
var ticking = false;

$(function () {
    addClickEvents();
    onReaderReady(100);
    setReaderTextSize(14)

    // hideAllVerses()
    // showRange()

    $(document).ready(function () {
    });

    // var regex = /([12]?[A-Z][a-z]{1,2})(\.?\s)?(\d+)(:\d+)?(-?–?\d+(:\d+)?)?((\,\s)?(\d+)(:\d+)?(-?–?\d+(:\d+)?)?)*/g

    var regex = /(Kej|Kel|Im|Bil|Ul|Yos|Hak|Rut|1Sam|2Sam|1Raj|2Raj|1Taw|2Taw|Ezr|Neh|Est|Ayb|Mzm|Ams|Pkh|Kid|Yes|Yer|Rat|Yeh|Dan|Hos|Yl|Am|Ob|Yun|Mi|Nah|Hab|Zef|Hag|Za|Mal|Tob|Ydt|T.Est|Keb|Sir|Bar|T.Dan|1Mak|2Mak|Mat|Mrk|Luk|Yoh|Kis|Rm|1Kor|2Kor|Gal|Ef|Flp|Kol|1Tes|2Tes|1Tim|2Tim|Tit|Flm|Ibr|Yak|1Ptr|2Ptr|1Yoh|2Yoh|3Yoh|Yud|Why)(\.?\s)?(\d+)(:\d+)?(-?–?\d+(:\d+)?)?((\,\s)?(\d+)(:\d+)?(-?–?\d+(:\d+)?)?)*/g

    $('span:not(:has(>span))').html(function (i, v) {
        return v.replace(regex, '<span class="ayat">$&</span>');
    });

    $('.ayat').on('click', function () {
        //window.ReactNativeWebView.postMessage('show_alkitab_referensi##' + $(this).text())
    })

    document.addEventListener("message", function (data) {
        // temp = JSON.parse(data.data)
        // temp.forEach((element) => {

        // })
        // selectVerses([1, 2, 3])
        data = JSON.parse(data.data)

        if (data.command == 'highlight') {
            setHighlights(data.params)
        } else if (data.command == 'select') {
            selectVerses(data.params)

            if (data.params.length == 0) {
                deselectVerse(1);
            }
        } else if (data.command == 'set_to_dark_theme') {
            $('.light').removeClass('light').addClass('dark');
            $('.brown').removeClass('brown').addClass('dark');

            $('body').css('background-color', '#000001')
        } else if (data.command == 'set_to_light_theme') {
            $('.dark').removeClass('dark').addClass('light');
            $('.brown').removeClass('brown').addClass('light');

            $('body').css('background-color', 'transparent')
        } else if (data.command == 'set_to_brown_theme') {
            $('.dark').removeClass('dark').addClass('brown');
            $('.light').removeClass('light').addClass('brown');

            $('body').css('background-color', '#F8ECC2')
        } else if (data.command == 'set_font_size') {
            setReaderTextSize(data.params)

            if (data.platform == 'android') {
                setTimeout(function () {
                    //window.postMessage('doc_height##' + ($('#last').offset().top))
                }, 500);
            } else {
                setTimeout(function () {
                    //window.postMessage('doc_height##' + document.getElementById('reader').scrollHeight)
                }, 500);
            }
        } else if (data.command == 'set_font_family') {
            $('span').css("font-family", data.params);

            if (data.platform == 'android') {
                setTimeout(function () {
                    //window.postMessage('doc_height##' + ($('#last').offset().top))
                }, 500);
            } else {
                setTimeout(function () {
                    //window.postMessage('doc_height##' + document.getElementById('reader').scrollHeight)
                }, 500);
            }
        } else if (data.command == 'request_scroll_height') {
            if (data.platform == 'android') {
                //window.postMessage('doc_height##' + ($('#last').offset().top))
            } else {
                //window.postMessage('doc_height##' + document.getElementById('reader').scrollHeight)
            }
        } else if (data.command == 'scroll_to') {
            setTimeout(function () {
                //window.postMessage('scroll_position##' + ($('.v' + data.params).offset().top))
            }, 500);
        } else if (data.command == 'get_text_to_save_at_history') {
            var temp = [];
            // var idx = 0;

            $('.verse[data-usfm].v' + data.params + ' .content').each(function () {
                temp.push($(this).text().trim())
            });

            //window.postMessage('text_to_save_at_history##' + temp.join(' ').replace(/\s+([.,!":;])/g, "$1"))
        }
    });
});

var failures = 0;

function onReaderReady(t) {
    setTimeout(function () {
        try {
            //android.onLoaded();
        } catch (e) {
            console.log('error calling onLoaded()');
            console.log(e);
            if (failures < 3) {
                failures++;
                onReaderReady(500);
            }
        }
    }, t);
}

function sendTextToSaveHistory(ayat) {
    var temp = [];

    $('.verse[data-usfm].v' + ayat + ' .content').each(function () {
        temp.push($(this).text().trim())
    });

    //window.ReactNativeWebView.postMessage('text_to_save_at_history##' + temp.join(' ').replace(/\s+([.,!":;])/g, "$1"))
}

function reloadAlkitabWebView(platform, highlight, scroll_to) {

    // if (theme == 'light') {
    //     $('.dark').removeClass('dark').addClass('light');
    //     $('body').css('background-color', 'transparent')
    // } else {
    //     $('.light').removeClass('light').addClass('dark');
    //     $('body').css('background-color', '#000001')
    // }

    // $('span').css("font-family", font_family);

    // setReaderTextSize(font_size);

    setHighlights(highlight);

    setTimeout(function () {
        if (platform == 'android') {
            //window.ReactNativeWebView.postMessage('doc_height##' + ($('#last').offset().top))
        } else {
            //window.ReactNativeWebView.postMessage('doc_height##' + document.getElementById('reader').scrollHeight)
        }

        if (scroll_to != 0) {
            //window.ReactNativeWebView.postMessage('scroll_position##' + ($('.v' + scroll_to).offset().top))
        }
    }, 500);
}

function addClickEvents() {
    $('table.table').addClass('responsive');

    $('.note > .label').click(function () {
        var note_label = $(this);
        var note = $(this).parent();
        var note_body = $(this).next();
        note.css('width', '100%');
        note_body.show().css("display", "block");
        note_body.append("<a href='#' class='note_close'> Tutup </a>");
        note_label.hide();

        $('.note_close').click(function () {
            disableVerseClick = true;
            var note = $(this).closest('.note');
            note.css('width', 'auto');
            note.css('display', 'inline-block');
            note.find('.body').hide();
            note.find('.label').css('display', 'inline-block');

            $(this).remove();
            return false;
        });

        return false;
    });

    allVerseContentElements().click(function (e) {
        var allusfm = $(this).closest('.verse').data('usfm');
        var usfm = $(this).closest('.verse').data('usfm');
        var temp = [];

        var datausfm = usfm.toString().split(' ')

        if (datausfm.length > 1) {
            usfm = datausfm[Math.floor(datausfm.length / 2)];
        }

        $('.verse[data-usfm].v' + usfm + ' .content').each(function () {
            temp.push($(this).text().trim())
        });

        //window.ReactNativeWebView.postMessage('selectVerse##' + usfm + '##' + allusfm + '##' + temp.join(' ').replace(/\s+([.,!":;])/g, "$1"))
    });
}

function setReaderTextSize(size) {
    $("body").css('font-size', size + 'pt');
}

function hideNotes() {
    $('head').append("<style id='setting-notes'>.note { display: none; }</style>");
}
function showNotes() {
    $('#setting-notes').remove();
}

function hideRedLetters() {
    $('head').append("<style id='setting-redletters'>.wj { color: inherit !important; }</style>");
}
function showRedLetters() {
    $('#setting-redletters').remove();
}

function closeNote(elem) {
    elem.parentNode.style.display = "none";
}

function selectVerses(verses) {
    clearSelection();

    for (var i = 0; i < verses.length; i++) {
        verses[i] = "" + verses[i];
        verseElementsForVerse(verses[i]).addClass('selected');
    }

    $('.selected > .selected').removeClass('selected');

    allVerseElements().addClass('selecting');

    var highlights = $('.highlighted > .label');
    highlights.each(function (index) {
        var elem = $(this);
        var obj = elem.closest('.verse[data-usfm]').data('highlightObject');
        if (obj) {
            // elem.css('color', obj['fgColor']);
            elem.css('color', '#000');
            elem.css('background-color', obj['bgColor']);
        }
    });
}

function selectVerse(verse) {
    verseElementsForVerse(verse).addClass('selected');
    allVerseElements().addClass('selecting');

    // turn off highlight color when selecting
    var highlights = $('.highlighted > .label');
    highlights.each(function (index) {
        var elem = $(this);
        var obj = elem.closest('.verse[data-usfm]').data('highlightObject');
        if (obj) {
            elem.css('color', obj['fgColor']);
            elem.css('background-color', obj['bgColor']);
        }
    });
}

function deselectVerse(verse) {
    verseElementsForVerse(verse).removeClass('selected');

    if ($('.selected').length == 0) {
        //there are no more selected verses, so remove "selecting" from all verses
        allVerseElements().removeClass('selecting');
        allVerseLabelElements().css('color', '');
        allVerseLabelElements().css('backgroundColor', '');
        allVerseLabelElements().css('text-shadow', '');
    }
}

function scrollToVerse(verse) {
    //android.scrollTo(offsetTopForVerse(verse));
}

function setActiveAudioVerse(curVerse) { /* curVerse is a string */
    var verseA = $('.v' + curVerse);
    if (verseA.length == 0)
        return;
    var verseATop = verseA.eq(0).offset().top;
    if (verseA.length == 1) {
        var height = verseA.height();
        //android.activeVersePos(verseATop, height);
    } else {
        var verseB = verseA.eq(verseA.length - 1);
        var height = verseB.offset().top - verseATop + verseB.height();
        //android.activeVersePos(verseATop, height);
    }
}

function clearSelection() {
    $('.selected').removeClass('selected');
    $('.selecting').removeClass('selecting');
}

function clearHighlights() {
    $('.highlighted').each(function (index) {
        var elem = $(this);
        elem.css('color', '');
        elem.css('background-color', '');
        //        elem.css('text-shadow', '');

        elem.removeData('highlightObject');
    });

    allVerseLabelElements().each(function (index) {
        var elem = $(this);
        elem.css('color', '');
        elem.css('background-color', '');
        //        elem.css('text-shadow', '');
    });

    var verseElements = allVerseElements();
    verseElements.removeClass('highlighted');
}

function setHighlights(arr) {
    clearHighlights();

    // arr = [{ verse: 1, fgColor: '', bgColor: '' }]

    for (var i = 0; i < arr.length; i++) {
        var obj = arr[i];
        var verse = obj['verse'];
        // verse = 1 / 2 / 3 / ...
        var v = verseElementsForVerse(verse);
        v.addClass('highlighted');

        v.css('color', tinycolor.mostReadable(obj['bgColor'], ["#000", "#fff"]).toHexString());
        v.css('background-color', obj['bgColor']);
        //v.css('text-shadow', obj['textShadowColor']);

        v.data('highlightObject', obj);
    }
}

//hiding and showing verses

function showAllVerses() {
    allVerseElements().css('display', '');
    $('.note').css('display', '');

    hiddenVerses = [];
    hideOrShowHeadingsAndAllButtonIfNeeded();

    //android.showAllVerses();
}

function showAllAndScrollToVerse(verse) {
    disableVerseClick = true;
    showAllVerses();
    scrollToVerse(verse);
}


function hideAllVerses() {
    var verses = allVerseElements();
    verses.css('display', 'none');
    verses.each(function (index) {
        var elem = $(this);
        var usfm = elem.data('usfm');
        if (!usfm) return true;         //equivalent to "continue;"

        var usfmComps = usfm.split('.');
        var verse = usfmComps[usfmComps.length - 1];
        if (verse.length > 0) {
            hiddenVerses.push('v' + verse);
        }
    });

    $('.note').css('display', 'none');

    hideOrShowHeadingsAndAllButtonIfNeeded();
}

function showRange(verseArray) {
    if (verseArray && verseArray.length > 0) {
        hideAllVerses();

        for (var i = 0; i < verseArray.length; i++) {
            showVerse(verseArray[i]);
        }

        hideOrShowHeadingsAndAllButtonIfNeeded();
    }
}

function showVerse(verse) {
    var verseComps = verseElementsForVerse(verse);
    if (verseComps.length == 0) { return; }

    verseComps.css('display', '');
    // find the index of the particular verse in the global "hiddenVerses" array, if it is there
    var verseClass = 'v' + verse;
    $('.' + verseClass + '> .note').css('display', '');

    var idx = -1;
    for (var i = 0; i < hiddenVerses.length; i++) {
        idx = (verseClass == hiddenVerses[i]) ? i : -1;
        if (idx != -1) break;
    }

    if (idx != -1) {
        //this verse is in the hiddenVerses array, so remove it
        hiddenVerses.splice(idx, 1);
    }
}


function hideOrShowHeadingsAndAllButtonIfNeeded() {
    var headingDisplayStyle = 'inline';
    var showAllButtonDisplayStyle = 'none';

    if (hiddenVerses.length > 0) {
        headingDisplayStyle = 'none';
        showAllButtonDisplayStyle = 'block';

        hideVerticalSpaces();
    }
    else {
        $('div.b').show();
    }

    $('#containerButton').css('display', showAllButtonDisplayStyle);

    $('.heading').css('display', headingDisplayStyle);
}


function hideVerticalSpaces() {
    $('div.b').each(function (i, elem) {
        // need to check if the previous and next siblings have no visible children
        var hasVisiblePrev = $(elem).prev().find(':visible').length > 0;
        var hasVisibleNext = $(elem).next().find(':visible').length > 0;

        if (!hasVisiblePrev || !hasVisibleNext) {
            $(elem).hide();
        }
        else {
            $(elem).show();
        }
    });
}

// Helpers

function allVerseElements() {
    return $('.verse[data-usfm]');
}


function verseElementsForVerse(verse) {
    return $('.verse[data-usfm].v' + verse);
}


function allVerseContentElements() {
    return $('.verse .content');
}


function allVerseLabelElements() {
    return $('.verse[data-usfm] > .label');
}


function offsetTopForVerse(verse) {
    var verses = $('.v' + verse);
    if (verses.length == 0) { return 0; }

    return verses.eq(0).offset().top;
}