export default [
    {
        "nama_kitab_pendek": "Kej",
        "nama_kitab_panjang": "Kejadian",
        "no_urut": 1,
        "data": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
            "32",
            "33",
            "34",
            "35",
            "36",
            "37",
            "38",
            "39",
            "40",
            "41",
            "42",
            "43",
            "44",
            "45",
            "46",
            "47",
            "48",
            "49",
            "50"
        ]
    },
    {
        "nama_kitab_pendek": "Kel",
        "nama_kitab_panjang": "Keluaran",
        "no_urut": 2,
        "data": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
            "32",
            "33",
            "34",
            "35",
            "36",
            "37",
            "38",
            "39",
            "40"
        ]
    },
    {
        "nama_kitab_pendek": "Im",
        "nama_kitab_panjang": "Imamat",
        "no_urut": 3,
        "data": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27"
        ]
    },
    {
        "nama_kitab_pendek": "Bil",
        "nama_kitab_panjang": "Bilangan",
        "no_urut": 4,
        "data": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
            "32",
            "33",
            "34",
            "35",
            "36"
        ]
    },
    {
        "nama_kitab_pendek": "Ul",
        "nama_kitab_panjang": "Ulangan",
        "no_urut": 5,
        "data": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
            "32",
            "33",
            "34"
        ]
    },
    {
        "nama_kitab_pendek": "Yos",
        "nama_kitab_panjang": "Yosua",
        "no_urut": 6,
        "data": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24"
        ]
    },
    {
        "nama_kitab_pendek": "Hak",
        "nama_kitab_panjang": "Hakim-Hakim",
        "no_urut": 7,
        "data": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21"
        ]
    },
    {
        "nama_kitab_pendek": "Rut",
        "nama_kitab_panjang": "Rut",
        "no_urut": 8,
        "data": [
            "1",
            "2",
            "3",
            "4"
        ]
    },
    {
        "nama_kitab_pendek": "1Sam",
        "nama_kitab_panjang": "1 Samuel",
        "no_urut": 9,
        "data": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31"
        ]
    },
    {
        "nama_kitab_pendek": "2Sam",
        "nama_kitab_panjang": "2 Samuel",
        "no_urut": 10,
        "data": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24"
        ]
    },
    {
        "nama_kitab_pendek": "1Raj",
        "nama_kitab_panjang": "1 Raja-Raja",
        "no_urut": 11,
        "data": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22"
        ]
    },
    {
        "nama_kitab_pendek": "2Raj",
        "nama_kitab_panjang": "2 Raja-Raja",
        "no_urut": 12,
        "data": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25"
        ]
    },
    {
        "nama_kitab_pendek": "1Taw",
        "nama_kitab_panjang": "1 Tawarikh",
        "no_urut": 13,
        "data": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29"
        ]
    },
    {
        "nama_kitab_pendek": "2Taw",
        "nama_kitab_panjang": "2 Tawarikh",
        "no_urut": 14,
        "data": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
            "32",
            "33",
            "34",
            "35",
            "36"
        ]
    },
    {
        "nama_kitab_pendek": "Ezr",
        "nama_kitab_panjang": "Ezra",
        "no_urut": 15,
        "data": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10"
        ]
    },
    {
        "nama_kitab_pendek": "Neh",
        "nama_kitab_panjang": "Nehemia",
        "no_urut": 16,
        "data": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
        ]
    },
    {
        "nama_kitab_pendek": "Est",
        "nama_kitab_panjang": "Ester",
        "no_urut": 17,
        "data": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10"
        ]
    },
    {
        "nama_kitab_pendek": "Ayb",
        "nama_kitab_panjang": "Ayub",
        "no_urut": 18,
        "data": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
            "32",
            "33",
            "34",
            "35",
            "36",
            "37",
            "38",
            "39",
            "40",
            "41",
            "42"
        ]
    },
    {
        "nama_kitab_pendek": "Mzm",
        "nama_kitab_panjang": "Mazmur",
        "no_urut": 19,
        "data": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
            "32",
            "33",
            "34",
            "35",
            "36",
            "37",
            "38",
            "39",
            "40",
            "41",
            "42",
            "43",
            "44",
            "45",
            "46",
            "47",
            "48",
            "49",
            "50",
            "51",
            "52",
            "53",
            "54",
            "55",
            "56",
            "57",
            "58",
            "59",
            "60",
            "61",
            "62",
            "63",
            "64",
            "65",
            "66",
            "67",
            "68",
            "69",
            "70",
            "71",
            "72",
            "73",
            "74",
            "75",
            "76",
            "77",
            "78",
            "79",
            "80",
            "81",
            "82",
            "83",
            "84",
            "85",
            "86",
            "87",
            "88",
            "89",
            "90",
            "91",
            "92",
            "93",
            "94",
            "95",
            "96",
            "97",
            "98",
            "99",
            "100",
            "101",
            "102",
            "103",
            "104",
            "105",
            "106",
            "107",
            "108",
            "109",
            "110",
            "111",
            "112",
            "113",
            "114",
            "115",
            "116",
            "117",
            "118",
            "119",
            "120",
            "121",
            "122",
            "123",
            "124",
            "125",
            "126",
            "127",
            "128",
            "129",
            "130",
            "131",
            "132",
            "133",
            "134",
            "135",
            "136",
            "137",
            "138",
            "139",
            "140",
            "141",
            "142",
            "143",
            "144",
            "145",
            "146",
            "147",
            "148",
            "149",
            "150"
        ]
    },
    {
        "nama_kitab_pendek": "Ams",
        "nama_kitab_panjang": "Amsal",
        "no_urut": 20,
        "data": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31"
        ]
    },
    {
        "nama_kitab_pendek": "Pkh",
        "nama_kitab_panjang": "Pengkhotbah",
        "no_urut": 21,
        "data": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12"
        ]
    },
    {
        "nama_kitab_pendek": "Kid",
        "nama_kitab_panjang": "Kidung Agung",
        "no_urut": 22,
        "data": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8"
        ]
    },
    {
        "nama_kitab_pendek": "Yes",
        "nama_kitab_panjang": "Yesaya",
        "no_urut": 23,
        "data": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
            "32",
            "33",
            "34",
            "35",
            "36",
            "37",
            "38",
            "39",
            "40",
            "41",
            "42",
            "43",
            "44",
            "45",
            "46",
            "47",
            "48",
            "49",
            "50",
            "51",
            "52",
            "53",
            "54",
            "55",
            "56",
            "57",
            "58",
            "59",
            "60",
            "61",
            "62",
            "63",
            "64",
            "65",
            "66"
        ]
    },
    {
        "nama_kitab_pendek": "Yer",
        "nama_kitab_panjang": "Yeremia",
        "no_urut": 24,
        "data": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
            "32",
            "33",
            "34",
            "35",
            "36",
            "37",
            "38",
            "39",
            "40",
            "41",
            "42",
            "43",
            "44",
            "45",
            "46",
            "47",
            "48",
            "49",
            "50",
            "51",
            "52"
        ]
    },
    {
        "nama_kitab_pendek": "Rat",
        "nama_kitab_panjang": "Ratapan",
        "no_urut": 25,
        "data": [
            "1",
            "2",
            "3",
            "4",
            "5"
        ]
    },
    {
        "nama_kitab_pendek": "Yeh",
        "nama_kitab_panjang": "Yehezkiel",
        "no_urut": 26,
        "data": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
            "32",
            "33",
            "34",
            "35",
            "36",
            "37",
            "38",
            "39",
            "40",
            "41",
            "42",
            "43",
            "44",
            "45",
            "46",
            "47",
            "48"
        ]
    },
    {
        "nama_kitab_pendek": "Dan",
        "nama_kitab_panjang": "Daniel",
        "no_urut": 27,
        "data": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12"
        ]
    },
    {
        "nama_kitab_pendek": "Hos",
        "nama_kitab_panjang": "Hosea",
        "no_urut": 28,
        "data": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14"
        ]
    },
    {
        "nama_kitab_pendek": "Yl",
        "nama_kitab_panjang": "Yoel",
        "no_urut": 29,
        "data": [
            "1",
            "2",
            "3"
        ]
    },
    {
        "nama_kitab_pendek": "Am",
        "nama_kitab_panjang": "Amos",
        "no_urut": 30,
        "data": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9"
        ]
    },
    {
        "nama_kitab_pendek": "Ob",
        "nama_kitab_panjang": "Obaja",
        "no_urut": 31,
        "data": [
            "1"
        ]
    },
    {
        "nama_kitab_pendek": "Yun",
        "nama_kitab_panjang": "Yunus",
        "no_urut": 32,
        "data": [
            "1",
            "2",
            "3",
            "4"
        ]
    },
    {
        "nama_kitab_pendek": "Mi",
        "nama_kitab_panjang": "Mikha",
        "no_urut": 33,
        "data": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7"
        ]
    },
    {
        "nama_kitab_pendek": "Nah",
        "nama_kitab_panjang": "Nahum",
        "no_urut": 34,
        "data": [
            "1",
            "2",
            "3"
        ]
    },
    {
        "nama_kitab_pendek": "Hab",
        "nama_kitab_panjang": "Habakuk",
        "no_urut": 35,
        "data": [
            "1",
            "2",
            "3"
        ]
    },
    {
        "nama_kitab_pendek": "Zef",
        "nama_kitab_panjang": "Zefanya",
        "no_urut": 36,
        "data": [
            "1",
            "2",
            "3"
        ]
    },
    {
        "nama_kitab_pendek": "Hag",
        "nama_kitab_panjang": "Hagai",
        "no_urut": 37,
        "data": [
            "1",
            "2"
        ]
    },
    {
        "nama_kitab_pendek": "Za",
        "nama_kitab_panjang": "Zakharia",
        "no_urut": 38,
        "data": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14"
        ]
    },
    {
        "nama_kitab_pendek": "Mal",
        "nama_kitab_panjang": "Maleakhi",
        "no_urut": 39,
        "data": [
            "1",
            "2",
            "3",
            "4"
        ]
    },
    {
        "nama_kitab_pendek": "Tob",
        "nama_kitab_panjang": "Tobit",
        "no_urut": 40,
        "data": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14"
        ]
    },
    {
        "nama_kitab_pendek": "Ydt",
        "nama_kitab_panjang": "Yudit",
        "no_urut": 41,
        "data": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16"
        ]
    },
    {
        "nama_kitab_pendek": "T.Est",
        "nama_kitab_panjang": "Tambahan Ester",
        "no_urut": 42,
        "data": [
            "A",
            "B",
            "4",
            "C",
            "D",
            "E",
            "F"
        ]
    },
    {
        "nama_kitab_pendek": "Keb",
        "nama_kitab_panjang": "Kebijaksanaan Salomo",
        "no_urut": 43,
        "data": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19"
        ]
    },
    {
        "nama_kitab_pendek": "Sir",
        "nama_kitab_panjang": "Yesus bin Sirakh",
        "no_urut": 44,
        "data": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
            "32",
            "33",
            "34",
            "35",
            "36",
            "37",
            "38",
            "39",
            "40",
            "41",
            "42",
            "43",
            "44",
            "45",
            "46",
            "47",
            "48",
            "49",
            "50",
            "51"
        ]
    },
    {
        "nama_kitab_pendek": "Bar",
        "nama_kitab_panjang": "Barukh",
        "no_urut": 45,
        "data": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6"
        ]
    },
    {
        "nama_kitab_pendek": "T.Dan",
        "nama_kitab_panjang": "Tambahan Daniel",
        "no_urut": 46,
        "data": [
            "3",
            "13",
            "14"
        ]
    },
    {
        "nama_kitab_pendek": "1Mak",
        "nama_kitab_panjang": "1 Makabe",
        "no_urut": 47,
        "data": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16"
        ]
    },
    {
        "nama_kitab_pendek": "2Mak",
        "nama_kitab_panjang": "2 Makabe",
        "no_urut": 48,
        "data": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15"
        ]
    },
    {
        "nama_kitab_pendek": "Mat",
        "nama_kitab_panjang": "Matius",
        "no_urut": 49,
        "data": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28"
        ]
    },
    {
        "nama_kitab_pendek": "Mrk",
        "nama_kitab_panjang": "Markus",
        "no_urut": 50,
        "data": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16"
        ]
    },
    {
        "nama_kitab_pendek": "Luk",
        "nama_kitab_panjang": "Lukas",
        "no_urut": 51,
        "data": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24"
        ]
    },
    {
        "nama_kitab_pendek": "Yoh",
        "nama_kitab_panjang": "Yohanes",
        "no_urut": 52,
        "data": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21"
        ]
    },
    {
        "nama_kitab_pendek": "Kis",
        "nama_kitab_panjang": "Kisah Para Rasul",
        "no_urut": 53,
        "data": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28"
        ]
    },
    {
        "nama_kitab_pendek": "Rm",
        "nama_kitab_panjang": "Roma",
        "no_urut": 54,
        "data": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16"
        ]
    },
    {
        "nama_kitab_pendek": "1Kor",
        "nama_kitab_panjang": "1 Korintus",
        "no_urut": 55,
        "data": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16"
        ]
    },
    {
        "nama_kitab_pendek": "2Kor",
        "nama_kitab_panjang": "2 Korintus",
        "no_urut": 56,
        "data": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
        ]
    },
    {
        "nama_kitab_pendek": "Gal",
        "nama_kitab_panjang": "Galatia",
        "no_urut": 57,
        "data": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6"
        ]
    },
    {
        "nama_kitab_pendek": "Ef",
        "nama_kitab_panjang": "Efesus",
        "no_urut": 58,
        "data": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6"
        ]
    },
    {
        "nama_kitab_pendek": "Flp",
        "nama_kitab_panjang": "Filipi",
        "no_urut": 59,
        "data": [
            "1",
            "2",
            "3",
            "4"
        ]
    },
    {
        "nama_kitab_pendek": "Kol",
        "nama_kitab_panjang": "Kolose",
        "no_urut": 60,
        "data": [
            "1",
            "2",
            "3",
            "4"
        ]
    },
    {
        "nama_kitab_pendek": "1Tes",
        "nama_kitab_panjang": "1 Tesalonika",
        "no_urut": 61,
        "data": [
            "1",
            "2",
            "3",
            "4",
            "5"
        ]
    },
    {
        "nama_kitab_pendek": "2Tes",
        "nama_kitab_panjang": "2 Tesalonika",
        "no_urut": 62,
        "data": [
            "1",
            "2",
            "3"
        ]
    },
    {
        "nama_kitab_pendek": "1Tim",
        "nama_kitab_panjang": "1 Timotius",
        "no_urut": 63,
        "data": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6"
        ]
    },
    {
        "nama_kitab_pendek": "2Tim",
        "nama_kitab_panjang": "2 Timotius",
        "no_urut": 64,
        "data": [
            "1",
            "2",
            "3",
            "4"
        ]
    },
    {
        "nama_kitab_pendek": "Tit",
        "nama_kitab_panjang": "Titus",
        "no_urut": 65,
        "data": [
            "1",
            "2",
            "3"
        ]
    },
    {
        "nama_kitab_pendek": "Flm",
        "nama_kitab_panjang": "Filemon",
        "no_urut": 66,
        "data": [
            "1"
        ]
    },
    {
        "nama_kitab_pendek": "Ibr",
        "nama_kitab_panjang": "Ibrani",
        "no_urut": 67,
        "data": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13"
        ]
    },
    {
        "nama_kitab_pendek": "Yak",
        "nama_kitab_panjang": "Yakobus",
        "no_urut": 68,
        "data": [
            "1",
            "2",
            "3",
            "4",
            "5"
        ]
    },
    {
        "nama_kitab_pendek": "1Ptr",
        "nama_kitab_panjang": "1 Petrus",
        "no_urut": 69,
        "data": [
            "1",
            "2",
            "3",
            "4",
            "5"
        ]
    },
    {
        "nama_kitab_pendek": "2Ptr",
        "nama_kitab_panjang": "2 Petrus",
        "no_urut": 70,
        "data": [
            "1",
            "2",
            "3"
        ]
    },
    {
        "nama_kitab_pendek": "1Yoh",
        "nama_kitab_panjang": "1 Yohanes",
        "no_urut": 71,
        "data": [
            "1",
            "2",
            "3",
            "4",
            "5"
        ]
    },
    {
        "nama_kitab_pendek": "2Yoh",
        "nama_kitab_panjang": "2 Yohanes",
        "no_urut": 72,
        "data": [
            "1"
        ]
    },
    {
        "nama_kitab_pendek": "3Yoh",
        "nama_kitab_panjang": "3 Yohanes",
        "no_urut": 73,
        "data": [
            "1"
        ]
    },
    {
        "nama_kitab_pendek": "Yud",
        "nama_kitab_panjang": "Yudas",
        "no_urut": 74,
        "data": [
            "1"
        ]
    },
    {
        "nama_kitab_pendek": "Why",
        "nama_kitab_panjang": "Wahyu",
        "no_urut": 75,
        "data": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22"
        ]
    }
]