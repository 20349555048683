import {
    GANTI_TERJEMAHAN_ALKITAB,
    GANTI_LAST_OPEN_ALKITAB,
    SET_ACTIVE_MENU,
    SET_DIALOG_PILIHAN_TERJEMAHAN_ALKITAB,
    SET_DIALOG_PILIHAN_KITAB_ALKITAB,
} from './types';

export const gantiTerjemahanAlkitab = (data) => {
    return {
        type: GANTI_TERJEMAHAN_ALKITAB,
        payload: data
    }
};

export const gantiLastOpenAlkitab = (data) => {
    return {
        type: GANTI_LAST_OPEN_ALKITAB,
        payload: data
    }
};

export const setActiveMenu = (data) => {
    return {
        type: SET_ACTIVE_MENU,
        payload: data
    }
};

export const gantiDialogPilihanTerjemahan = (data) => {
    return {
        type: SET_DIALOG_PILIHAN_TERJEMAHAN_ALKITAB,
        payload: data
    }
};

export const gantiDialogPilihanKitab = (data) => {
    return {
        type: SET_DIALOG_PILIHAN_KITAB_ALKITAB,
        payload: data
    }
};