import axios from 'axios';
import appconfig from '../config/appconfig';

/**
 * mostly for Account & Profile
 */
export const httpreq = axios.create({
  timeout: 5000
});

const params = {
  client_version: appconfig.clientVersion,
  device_id: appconfig.deviceId,
  os: appconfig.OS
}

export const net = axios.create({
  baseURL: appconfig.apiUrl,
  data: params,
  // headers: {
    // 'Content-Type': 'application/x-www-form-urlencoded',
    // 'Cache-Control': 'no-cache',
    // 'content-type': 'application/json',
    // 'Access-Control-Allow-Origin': '*',
    // 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
  // }
})