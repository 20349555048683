import React from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { gantiKalenderLiturgiState, setAudioPlayer } from '../../../actions';
import moment from 'moment';
import PlayCircleOutline from '@material-ui/icons/PlayCircleOutline';
import PauseCircleOutline from '@material-ui/icons/PauseCircleOutline';
import CloseOutlined from '@material-ui/icons/CloseOutlined';
import Slider from '@material-ui/core/Slider';
import { withStyles, makeStyles } from '@material-ui/core/styles';

const Spinner = require('react-spinkit');

const iOSBoxShadow =
    '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const IOSSlider = withStyles({
    root: {
        color: 'white',
        height: 2,
        padding: '15px 0',
    },
    thumb: {
        height: 28,
        width: 28,
        backgroundColor: '#fff',
        boxShadow: iOSBoxShadow,
        marginTop: -14,
        marginLeft: -14,
        '&:focus, &:hover, &$active': {
            boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                boxShadow: iOSBoxShadow,
            },
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 12px)',
        top: -22,
        '& *': {
            background: 'transparent',
            color: '#FFF',
        },
    },
    track: {
        height: 2,
    },
    rail: {
        height: 2,
        opacity: 0.5,
        backgroundColor: '#bfbfbf',
    },
    mark: {
        backgroundColor: '#bfbfbf',
        height: 8,
        width: 1,
        marginTop: -3,
    },
    markActive: {
        opacity: 1,
        backgroundColor: 'currentColor',
    },
})(Slider);

function fancyTimeFormat(duration) {
    // Hours, minutes and seconds
    var hrs = ~~(duration / 3600);
    var mins = ~~((duration % 3600) / 60);
    var secs = ~~duration % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";

    if (hrs > 0) {
        ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
}

function Navbar(props) {
    const [value, setValue] = React.useState(30);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <ul className="main-menu nav" style={{ paddingLeft: 16, justifyContent: 'flex-start' }}>
            {
                props.audioPlayerState.audioPlayerState === 0
                    ?
                    <li>
                        <Link style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }} to="#" onClick={() => {
                        }}><Spinner style={{ color: 'white', marginRight: 8 }} name='double-bounce' />Loading Audio Player ...</Link>
                    </li>
                    :
                    <>
                        <li style={{ padding: 0, marginRight: 4 }}>
                            <Link style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                            }} to="#" onClick={() => {
                                props.setAudioPlayer({
                                    audioPlayerRef: React.createRef(),
                                    audioPlayerURL: null,
                                    audioPlayerJudul: '',
                                    audioPlayerKeterangan: 'Loading...',
                                    audioPlayerImage: '',
                                    audioPlayerLoading: false,
                                    audioPlayerState: 0,
                                    audioPlayerPlaying: false,
                                    audioPlayerDuration: 0,
                                    audioPlayerCurrentTime: 0,
                                    audioPlayerPlayableDuration: 0,
                                    audioPlayerSeekableDuration: 0,
                                })
                            }}>
                                <CloseOutlined style={{ fontSize: 32 }} />
                            </Link>
                        </li>
                        <li>
                            <Link style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }} to="#" onClick={() => {
                                if (props.audioPlayerState.audioPlayerPlaying) {
                                    props.setAudioPlayer({
                                        audioPlayerPlaying: false,
                                    })
                                } else {
                                    props.setAudioPlayer({
                                        audioPlayerPlaying: true,
                                    })
                                }
                            }}>
                                {
                                    props.audioPlayerState.audioPlayerPlaying
                                        ?
                                        <PauseCircleOutline style={{ marginRight: 8, fontSize: 32 }} />
                                        :
                                        <PlayCircleOutline style={{ marginRight: 8, fontSize: 32 }} />
                                }

                                &nbsp;&nbsp;<span style={{ textDecoration: 'underline', textUnderlineOffset: 3 }}>{props.audioPlayerState.audioPlayerJudul + ' • ' + props.audioPlayerState.audioPlayerKeterangan}</span></Link>
                        </li>
                        <li style={{ paddingLeft: 16, width: 400, display: 'flex', flexDirection: 'row' }}>
                            <IOSSlider
                                aria-label="ios slider"
                                value={props.audioPlayerState.audioPlayerCurrentTime}
                                min={0}
                                max={props.audioPlayerState.audioPlayerDuration}
                                onChange={(event, newValue) => {
                                    props.audioPlayerState.audioPlayerRef.current.seekTo(newValue);
                                }}
                                valueLabelFormat={fancyTimeFormat}
                                valueLabelDisplay="auto"
                            />
                            <Link style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginLeft: 16, width: 200
                            }} to="#">{fancyTimeFormat(props.audioPlayerState.audioPlayerCurrentTime)} / {fancyTimeFormat(props.audioPlayerState.audioPlayerDuration)}</Link>
                        </li>
                    </>
            }
        </ul>
    );
}

const mapStateToProps = state => {
    return {
        settingState: state.settingState,
        kalenderliturgiState: state.kalenderliturgiState,
        audioPlayerState: state.audioPlayerState,
    }
};

export default connect(mapStateToProps, { gantiKalenderLiturgiState, setAudioPlayer })(Navbar);