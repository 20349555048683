import Logo from './Logo'
import Navbar from './Navbar/Navbar'
import NavbarAlkitab from './Navbar/NavbarAlkitab'
import NavbarKalenderLiturgi from './Navbar/NavbarKalenderLiturgi'
import NavbarAudioPlayer from './Navbar/NavbarAudioPlayer'
import HeaderConfig from './HeaderConfig'
import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { } from '../../actions';

const Header = (props) => {
    const [isSticky, setIsSticky] = useState(false);

    const sticky = () => {
        const scrollTop = window.scrollY;
        scrollTop >= 250 ? setIsSticky(true) : setIsSticky(false);
    }

    useEffect(() => {
        window.addEventListener('scroll', sticky);

        return () => {
            window.removeEventListener('scroll', sticky);
        }
    }, []);

    return (
        <div>
            <header className={`header-area ${isSticky ? 'sticky' : 'not-sticky'}`}>
                <div className="container" style={{ paddingTop: 10, paddingBottom: 10 }}>
                    <div className="row" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                        <div className="col-5 col-lg-2">
                            <Logo />
                        </div>

                        <div className="col-lg-9 d-none d-lg-block">
                            <div className="navigation-area mt-lg-3">
                                <Navbar />
                            </div>
                        </div>

                        <div className="col-1 col-lg-3">
                            <HeaderConfig />
                        </div>
                    </div>
                </div>
                {
                    props.settingState.activeMenu === "Alkitab" &&
                    <div className={`header-action-area ${isSticky ? 'sticky' : 'not-sticky'}`}>
                        <div className="container">
                            <div className="row align-items-center">
                                <NavbarAlkitab />
                            </div>
                        </div>
                    </div>
                }
                {
                    props.settingState.activeMenu === "KalenderLiturgi" &&
                    <div className={`header-action-area ${isSticky ? 'sticky' : 'not-sticky'}`}>
                        <div className="container">
                            <div className="row align-items-center">
                                <NavbarKalenderLiturgi />
                            </div>
                        </div>
                    </div>
                }
                {
                    props.audioPlayerState.audioPlayerURL !== null &&
                    // true &&
                    <div className={`header-audioplayer ${isSticky ? 'sticky' : 'not-sticky'}`}>
                        <div className="container">
                            <div className="row align-items-center">
                                <NavbarAudioPlayer />
                            </div>
                        </div>
                    </div>
                }
            </header>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        settingState: state.settingState,
        audioPlayerState: state.audioPlayerState,
    }
};

export default connect(mapStateToProps, {})(Header);