import {
    GANTI_LAST_OPEN_ALKITAB,
    GANTI_TERJEMAHAN_ALKITAB,
    SET_ACTIVE_MENU,
    SET_DIALOG_PILIHAN_TERJEMAHAN_ALKITAB,
    SET_DIALOG_PILIHAN_KITAB_ALKITAB,
} from '../actions/types';

import TBIndex from '../config/TBIndex';
import BIMKIndex from '../config/BIMKIndex';

const INITIAL_STATE = {
    sideDrawerOpen: false,
    dialogPilihanTerjemahanAlkitab: false,
    dialogPilihanKitabAlkitab: false,
    terjemahanAlkitab: 'TB',
    activeMenu: 'Beranda',
    alkitabIndex: TBIndex,
    alkitabLastOpen: {
        nama_kitab_pendek: 'Mat',
        bab: '1',
        index_bab: 0,
    },
}


export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GANTI_LAST_OPEN_ALKITAB:
            return {
                ...state,
                alkitabLastOpen: action.payload,
            }
        case GANTI_TERJEMAHAN_ALKITAB:
            let alkitabIndex = TBIndex;
            if (action.payload === 'BIMK') alkitabIndex = BIMKIndex;

            return {
                ...state,
                alkitabIndex,
                terjemahanAlkitab: action.payload,
            }
        case SET_ACTIVE_MENU:
            return {
                ...state,
                activeMenu: action.payload,
            }
        case SET_DIALOG_PILIHAN_TERJEMAHAN_ALKITAB:
            return {
                ...state,
                dialogPilihanTerjemahanAlkitab: action.payload,
            }
        case SET_DIALOG_PILIHAN_KITAB_ALKITAB:
            return {
                ...state,
                dialogPilihanKitabAlkitab: action.payload,
            }
        default:
            return state;
    }
}