import React, { useState } from 'react';
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import Thumbnail from "../../UI/Thumbnail";
import Content from "../../UI/Content";

import List from "../../UI/List";
import LI from "../../UI/List/Item";

import aboutData from '../../../data/About/home-one'

const About = (data) => {

    const [about] = useState(aboutData);
    return (
        <div className="about-area-wrapper sm-top">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-lg-5">
                        {/* <h2>Kalender Liturgi Hari Ini</h2> */}
                        {
                            data && data.url_galeri_hari_ini
                                ?
                                <Thumbnail classes="about-thumb" imgSrc={data.url_galeri_hari_ini} />
                                :
                                <Thumbnail classes="about-thumb" imgSrc={require('../../../assets/img' + about.thumb)} />
                        }

                    </div>

                    {
                        data.datakalit
                            ?
                            <div className="col-md-6 col-lg-7">
                                <Content>
                                    <h2>Kalender Liturgi Hari Ini</h2>
                                    <p style={{ lineHeight: 2.4 }}>
                                        {data.datakalit.ket_minggu}<br />
                                        Warna Liturgi: {data.datakalit.warna}<br />
                                        Bacaan I: {data.datakalit.bacaan_pertama}<br />
                                        Mazmur Tanggapan: {data.datakalit.mazmur_tanggapan}<br />
                                        {
                                            data.datakalit.bacaan_kedua
                                                ?
                                                <span>{'Bacaan II: '} {data.datakalit.bacaan_kedua} <br /> </span>
                                                :
                                                ''
                                        }
                                        {
                                            data.datakalit.bait_pengantar_injil
                                                ?
                                                <span> {'Bait Pengantar Injil: '} {data.datakalit.bait_pengantar_injil} <br /> </span>
                                                :
                                                ''
                                        }
                                        Bacaan Injil: {data.datakalit.bacaan_injil}
                                    </p>
                                    <Link to={`${process.env.PUBLIC_URL + about.btnLink}`}
                                        className="btn-about">Baca dan Dengar Bacaan Hari Ini <i
                                            className="fa fa-angle-double-right" /></Link>
                                </Content>
                            </div>
                            :
                            null
                    }
                </div>
                <div className="row" style={{ paddingTop: 64 }}>
                    {
                        data.dataperayaanhariini
                            ?
                            <div className="col-md-6 col-lg-7">
                                <Content>
                                    <h2>Perayaan Hari Ini</h2>

                                    <List>
                                        {
                                            data.dataperayaanhariini.map(item => {
                                                return <LI key={item._id}><a href="#">{item.nama}</a></LI>
                                            })
                                        }

                                    </List>
                                </Content>
                            </div>
                            :
                            null
                    }
                </div>
            </div>
        </div>
    );
};

export default About;