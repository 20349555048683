import {
    GANTI_KALENDERLITURGI_TANGGAL,
    GANTI_KALENDERLITURGI_DATA,
    GANTI_KALENDERLITURGI_LOADING,
    GANTI_KALENDERLITURGI_ISSHOW_DIALOGPILIHANTANGGAL,
    GANTI_KALENDERLITURGI_ALL,
    KALENDERLITURGI_SHOWMESSAGE,
    GANTI_KALENDERLITURGI_STATE,
    GET_DATA_KALENDERLITURGI,
} from './types';

import { net } from '../utils/Networking';

export const gantiKalenderLiturgiState = (data) => {
    return {
        type: GANTI_KALENDERLITURGI_STATE,
        payload: data
    }
};


export const gantiKalenderLiturgiTanggal = (data) => {
    return {
        type: GANTI_KALENDERLITURGI_TANGGAL,
        payload: data
    }
};

export const gantiKalenderLiturgiData = (data) => {
    return {
        type: GANTI_KALENDERLITURGI_DATA,
        payload: data
    }
};

export const gantiKalenderLiturgiLoading = (data) => {
    return {
        type: GANTI_KALENDERLITURGI_LOADING,
        payload: data
    }
};

export const gantiKalenderLiturgiIsShowDialogPilihanTanggal = (data) => {
    return {
        type: GANTI_KALENDERLITURGI_ISSHOW_DIALOGPILIHANTANGGAL,
        payload: data
    }
};

export const gantiKalenderLiturgiAll = (data) => {
    return {
        type: GANTI_KALENDERLITURGI_ALL,
        payload: data
    }
};

export const kalenderliturgiShowMessage = (data) => {
    return {
        type: KALENDERLITURGI_SHOWMESSAGE,
        payload: data
    }
};

export const getDataKalenderLiturgi = (data) => {
    // return {
    //     type: GET_DATA_KALENDERLITURGI,
    //     payload: data
    // }
    return (dispatch) => {
        return net.post('webapp/get_kalender_liturgi', {
            tanggal: data
        })
            .then(response => {
                return response.data
            })
            .then(data => {
                dispatch({
                    type: GET_DATA_KALENDERLITURGI,
                    payload: data
                })
            })
            .catch(error => {
                throw (error);
            });
    };
};