import React from 'react';
import NavbarItem from './NavbarItem'
import { Link } from "react-router-dom";
import PlayCircleFilled from '@material-ui/icons/PlayCircleFilled';
import PauseCircleFilled from '@material-ui/icons/PauseCircleFilled';
import MusicNoteOutlined from '@material-ui/icons/MusicNoteOutlined';

import { connect } from 'react-redux';
import { } from '../../../actions';

function Navbar(props) {
    return (
        <ul className="main-menu nav" style={{ alignItems: 'center' }}>
            <NavbarItem />
            {/* {
                props.audioPlayerState.audioPlayer.audioPlayerSource !== null && <li key={"audioplayer"}>
                    <Link to="#"><MusicNoteOutlined style={{ fontSize: 48 }} /></Link>
                </li>} */}
        </ul>
    );
}

const mapStateToProps = state => {
    return {
        settingState: state.settingState,
        audioPlayerState: state.audioPlayerState,
    }
};

export default connect(mapStateToProps, {})(Navbar);