import { combineReducers } from 'redux';
import settingReducers from './settingReducers';
// import userReducer from './userReducer';
import kalenderliturgiReducers from './kalenderliturgiReducers';
import audioPlayerReducer from './audioPlayerReducer';

export default combineReducers({
    settingState: settingReducers,
    // userState: userReducer,
    kalenderliturgiState: kalenderliturgiReducers,
    audioPlayerState: audioPlayerReducer,
});
