import ReactDOM from 'react-dom';
import React, { Fragment, Component } from 'react';

import './index.css';
import './reader.css';
import './reader.js';

import SearchIcon from '@material-ui/icons/Search';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Accordion, Card, Button } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';

import Header from "../../components/Header";
import PageHeader from "../../components/PageHeader";
import About from "../../components/About/home-two";
import Services from "../../components/Services";
import BrandLogo from "../../components/BrandLogo";
import Funfact from "../../components/Funfact";
import CallToAction from "../../components/CallToAction";
import Footer from "../../components/Footer";
import LoginRegister from "../../components/LoginRegister";
import MobileMenu from "../../components/MobileMenu";
import Features from "../../components/Features";
import Testimonial from "../../components/Testimonials/home-two";
import Team from "../../components/Team/home-two";

import ServiceThumb from '../../assets/img/about.jpg'

import { net } from '../../utils/Networking'

import { connect } from 'react-redux';
import { gantiTerjemahanAlkitab, gantiLastOpenAlkitab, setActiveMenu, gantiDialogPilihanTerjemahan, gantiDialogPilihanKitab } from '../../actions';

function CustomToggle({ children, eventKey, data }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
        console.log('totally custom!'),
    );

    return (
        <div
            key={data.nama_kitab_panjang}
            className="itemPilihanModal"
            style={{ paddingBottom: '4px', paddingTop: '4px' }}
            onClick={decoratedOnClick}>
            <a>{data.nama_kitab_panjang}</a>
        </div>
    );
}

class Alkitab extends Component {
    constructor(props) {
        super(props)

        this.state = {
            pilihanTerjemahanAlkitab: false,
            pilihanKitab: false,
            // dataKitab: props.settingState.alkitabIndex,
            isiKitab: '',
        }

        this.renderIsi = this.renderIsi.bind(this);
    }

    componentDidMount() {
        this.props.setActiveMenu("Alkitab");
        net.post('webapp/get_isi_kitab', {
            nama_terjemahan_pendek: this.props.settingState.terjemahanAlkitab,
            nama_kitab_pendek: this.props.settingState.alkitabLastOpen.nama_kitab_pendek,
            index_bab: 0,
        })
            .then(response => {
                let data = response.data //.replace(/class/g, 'className');
                this.setState({
                    isiKitab: data,
                })
            });
    }

    createMarkup() {
        return { __html: this.state.isiKitab };
    }

    renderIsi() {
        return <div dangerouslySetInnerHTML={this.createMarkup()} />;
    }



    render() {
        let tempData = this.props.settingState.alkitabIndex;

        if (this.state.txt_cari_kitab !== "") {
            let result = [];

            for (let i = 0; i < tempData.length; i++) {
                if (tempData[i].nama_kitab_panjang.search(new RegExp(this.state.txt_cari_kitab, "i")) !== -1) {
                    result.push(tempData[i])
                }
            }

            tempData = result;
        }

        return (
            <Fragment>
                <Header />
                <div className="container">
                    <div className="row">
                        {
                            (this.props.settingState.dialogPilihanTerjemahanAlkitab)
                                ?
                                <div className="pilihanModal" style={{ marginTop: 20, padding: 0 }}>
                                    <div className="judulPilihanModal">
                                        <div>
                                            <span>Pilih Terjemahan Alkitab</span>
                                        </div>
                                        <div className="spacer" />
                                        <div>
                                            <span onClick={() => {
                                                // this.setState({ pilihanTerjemahanAlkitab: false })
                                                this.props.gantiDialogPilihanTerjemahan(false)
                                            }}>Tutup</span>
                                        </div>
                                    </div>
                                    <div style={{ padding: '16px' }}>
                                        <div className="itemPilihanModal" style={{ paddingBottom: '4px', paddingTop: '4px' }} onClick={() => {
                                            this.props.gantiTerjemahanAlkitab('TB');

                                            net.post('webapp/get_isi_kitab', {
                                                nama_terjemahan_pendek: 'TB',
                                                nama_kitab_pendek: this.props.settingState.alkitabLastOpen.nama_kitab_pendek,
                                                index_bab: this.props.settingState.alkitabLastOpen.index_bab,
                                            })
                                                .then(response => {
                                                    let data = response.data //.replace(/class/g, 'className');
                                                    this.setState({
                                                        isiKitab: data,
                                                    })
                                                    this.props.gantiDialogPilihanTerjemahan(false)
                                                });
                                        }}>
                                            <a>Terjemahan Baru</a>
                                        </div>
                                        <div className="itemPilihanModal" style={{ paddingBottom: '4px', paddingTop: '4px' }} onClick={() => {
                                            this.props.gantiTerjemahanAlkitab('BIMK');
                                            ReactDOM.findDOMNode(this).scrollIntoView();

                                            net.post('webapp/get_isi_kitab', {
                                                nama_terjemahan_pendek: 'BIMK',
                                                nama_kitab_pendek: this.props.settingState.alkitabLastOpen.nama_kitab_pendek,
                                                index_bab: this.props.settingState.alkitabLastOpen.index_bab,
                                            })
                                                .then(response => {
                                                    let data = response.data //.replace(/class/g, 'className');
                                                    this.setState({
                                                        isiKitab: data,
                                                    })
                                                    this.props.gantiDialogPilihanTerjemahan(false)
                                                });

                                        }}>
                                            <a>Bahasa Indonesia Masa Kini</a>
                                        </div>
                                    </div>
                                </div>
                                :
                                (this.props.settingState.dialogPilihanKitabAlkitab)
                                    ?
                                    <div className="pilihanModal" style={{ marginTop: 20, padding: 0 }}>
                                        <div className="judulPilihanModal">
                                            <div>
                                                <span>{this.props.settingState.terjemahanAlkitab}</span>
                                            </div>
                                            <div className="spacer" />
                                            <div>
                                                <span onClick={() => {
                                                    this.props.gantiDialogPilihanKitab(false)
                                                }}>Tutup</span>
                                            </div>
                                        </div>
                                        <div style={{ padding: '16px' }}>
                                            <Input
                                                style={{ marginBottom: '16px' }}
                                                fullWidth={true}
                                                id="input-with-icon-adornment"
                                                onChange={(event) => this.setState({ txt_cari_kitab: event.target.value })}
                                                startAdornment={
                                                    <InputAdornment
                                                        // value={this.state.txt_cari_kitab}
                                                        // onChange={(event) => this.setState({ txt_cari_kitab: event.target.value })}
                                                        position="start">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                }
                                            />
                                            <Accordion>
                                                {
                                                    tempData.map((item, index) => {
                                                        return (
                                                            <div>
                                                                <CustomToggle eventKey={index} data={item} />
                                                                <Accordion.Collapse eventKey={index}>
                                                                    <div>
                                                                        {
                                                                            item.data.map((bab, i) => {
                                                                                return <div key={item.nama_kitab_pendek + bab} className="container-bab" onClick={() => {
                                                                                    this.props.gantiLastOpenAlkitab({
                                                                                        nama_kitab_pendek: item.nama_kitab_pendek,
                                                                                        bab: bab,
                                                                                        index_bab: i,
                                                                                    });

                                                                                    ReactDOM.findDOMNode(this).scrollIntoView();

                                                                                    net.post('webapp/get_isi_kitab', {
                                                                                        nama_terjemahan_pendek: this.props.settingState.terjemahanAlkitab,
                                                                                        nama_kitab_pendek: item.nama_kitab_pendek,
                                                                                        index_bab: i,
                                                                                    })
                                                                                        .then(response => {
                                                                                            let data = response.data //.replace(/class/g, 'className');

                                                                                            this.setState({
                                                                                                isiKitab: data,
                                                                                                txt_cari_kitab: '',
                                                                                            })

                                                                                            this.props.gantiDialogPilihanKitab(false)
                                                                                        });
                                                                                }}>{bab}</div>;
                                                                            })
                                                                        }
                                                                    </div>
                                                                </Accordion.Collapse>
                                                            </div>

                                                        )
                                                    })
                                                }
                                            </Accordion>
                                        </div>
                                    </div>
                                    :
                                    <div id="reader" className="light">
                                        {this.renderIsi()}
                                    </div>
                        }
                    </div>
                </div>
                <CallToAction />
                <Footer />
                <LoginRegister />
                <MobileMenu />
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return { settingState: state.settingState }
};

export default connect(mapStateToProps, { gantiTerjemahanAlkitab, gantiLastOpenAlkitab, setActiveMenu, gantiDialogPilihanTerjemahan, gantiDialogPilihanKitab })(Alkitab);