import { Link } from "react-router-dom";
import React, { Component } from 'react';
import logo from '../../assets/img/logo.png'

class Logo extends Component {
    render() {
        return (
            <div className="logo-area">
                <Link to="/">
                    <img style={{ width: 100 }} src={logo} alt="eKatolik-Logo" />
                </Link>
            </div>
        );
    }
}

export default Logo;