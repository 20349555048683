import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

/*
* @ All pages Import
*/
import HomeOne from './pages/HomeOne'
import HomeTwo from './pages/HomeTwo'
import Service from './pages/Service'
import ServiceDetails from "./pages/ServiceDetails";
import BlogGridRightSidebar from './pages/BlogGridRightSidebar';
import BlogGridLeftSidebar from './pages/BlogGridLeftSidebar';
import BlogGridWithoutSidebar from './pages/BlogGridWithoutSidebar';
import BlogListLeftSidebar from './pages/BlogListLeftSidebar';
import BlogListRightSidebar from './pages/BlogListRightSidebar';
import BlogDetailsPage from "./pages/BlogDetails";
import Team from "./pages/Team";
import TeamDetails from "./pages/TeamDetails";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Error404 from "./pages/Error404";
import ScrollToTop from "./helpers/ScrollToTop";
import ReactPlayer from 'react-player'

import Alkitab from "./pages/Alkitab";
import KalenderLiturgi from "./pages/KalenderLiturgi";
import Beranda from "./pages/Beranda";
import ComingSoon from "./pages/ComingSoon";

import { connect } from 'react-redux';
import { setAudioPlayer } from './actions';

const App = (props) => {
    return (
        <Router>
            {
                props.audioPlayerState.audioPlayerURL !== null && <ReactPlayer
                    ref={props.audioPlayerState.audioPlayerRef}
                    onReady={() => {
                        props.setAudioPlayer({
                            audioPlayerState: 1,
                            audioPlayerPlaying: true,
                        })
                    }}
                    onDuration={(duration) => {
                        props.setAudioPlayer({
                            audioPlayerDuration: duration,
                        })
                    }}
                    onProgress={(data) => {
                        props.setAudioPlayer({
                            audioPlayerCurrentTime: data.playedSeconds,
                        })
                    }}
                    onEnded={() => {
                        props.setAudioPlayer({
                            audioPlayerRef: React.createRef(),
                            audioPlayerURL: null,
                            audioPlayerJudul: '',
                            audioPlayerKeterangan: 'Loading...',
                            audioPlayerImage: '',
                            audioPlayerLoading: false,
                            audioPlayerState: 0,
                            audioPlayerPlaying: false,
                            audioPlayerDuration: 0,
                            audioPlayerCurrentTime: 0,
                            audioPlayerPlayableDuration: 0,
                            audioPlayerSeekableDuration: 0,
                        })
                    }}
                    width='0%'
                    height='0%'
                    playing={props.audioPlayerState.audioPlayerPlaying}
                    url={props.audioPlayerState.audioPlayerURL} />}
            {/* <ScrollToTop> */}
                <Switch>
                    <Route exact path={`${process.env.PUBLIC_URL + '/services'}`} component={Service} />
                    <Route path={`${process.env.PUBLIC_URL + '/service/:serviceID'}`} component={ServiceDetails} />
                    <Route exact path={`${process.env.PUBLIC_URL + "/blog-grid-right-sidebar"}`}
                        component={BlogGridRightSidebar} />
                    <Route exact path={`${process.env.PUBLIC_URL + "/blog-grid-left-sidebar"}`}
                        component={BlogGridLeftSidebar} />
                    <Route exact path={`${process.env.PUBLIC_URL + "/blog-grid-without-sidebar"}`}
                        component={BlogGridWithoutSidebar} />
                    <Route exact path={`${process.env.PUBLIC_URL + "/blog-list-left-sidebar"}`}
                        component={BlogListLeftSidebar} />
                    <Route exact path={`${process.env.PUBLIC_URL + "/blog-list-right-sidebar"}`}
                        component={BlogListRightSidebar} />
                    <Route path={`${process.env.PUBLIC_URL + "/blog/:blogID"}`} component={BlogDetailsPage} />
                    <Route exact path={`${process.env.PUBLIC_URL + "/team"}`} component={Team} />
                    <Route path={`${process.env.PUBLIC_URL + "/team-member/:teamID"}`} component={TeamDetails} />
                    <Route exact path={`${process.env.PUBLIC_URL + "/about"}`} component={About} />
                    <Route exact path={`${process.env.PUBLIC_URL + "/contact"}`} component={Contact} />
                    <Route exact path={`${process.env.PUBLIC_URL + '/home-one'}`} component={HomeOne} />
                    <Route exact path={`${process.env.PUBLIC_URL + '/home-two'}`} component={HomeTwo} />
                    <Route exact path={`${process.env.PUBLIC_URL + '/alkitab'}`} component={Alkitab} />
                    <Route path="/kalenderliturgi/:tanggal?" component={KalenderLiturgi} />
                    <Route exact path={`${process.env.PUBLIC_URL + '/kumpulandoa'}`} component={ComingSoon} />
                    <Route exact path={`${process.env.PUBLIC_URL + '/dailyfreshjuice'}`} component={ComingSoon} />
                    <Route exact path={`${process.env.PUBLIC_URL + '/renunganharian'}`} component={ComingSoon} />
                    <Route exact path={`${process.env.PUBLIC_URL + '/riwayatorangkudus'}`} component={ComingSoon} />
                    <Route exact path={`${process.env.PUBLIC_URL + '/ibadatharian'}`} component={ComingSoon} />
                    <Route exact path={`${process.env.PUBLIC_URL + '/'}`} component={Beranda} />
                    <Route exact component={Error404} />
                </Switch>
            {/* </ScrollToTop> */}
        </Router>
    );
};

const mapStateToProps = state => {
    return {
        settingState: state.settingState,
        audioPlayerState: state.audioPlayerState,
    }
};

export default connect(mapStateToProps, { setAudioPlayer })(App);