import React from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { gantiDialogPilihanTerjemahan, gantiDialogPilihanKitab } from '../../../actions';

function Navbar(props) {
    return (
        <ul className="main-menu nav" style={{ paddingLeft: 16, justifyContent: 'flex-start' }}>
            <li key={"menu-pilih-terjemahan-alkitab"} className="has-submenu">
                <Link to="#" onClick={() => {
                    props.gantiDialogPilihanTerjemahan(true);
                }}>{props.settingState.terjemahanAlkitab}</Link>
            </li>
            <li key={"menu-pilih-kitab-alkitab"} className="has-submenu">
                <Link to="#" onClick={() => {
                    props.gantiDialogPilihanKitab(true);
                }}>{props.settingState.alkitabLastOpen.nama_kitab_pendek + " " + props.settingState.alkitabLastOpen.bab}</Link>
            </li>
        </ul>
    );
}

const mapStateToProps = state => {
    return { settingState: state.settingState }
};

export default connect(mapStateToProps, { gantiDialogPilihanTerjemahan, gantiDialogPilihanKitab })(Navbar);