import React from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { gantiKalenderLiturgiState, getDataKalenderLiturgi } from '../../../actions';
import moment from 'moment';
import CalendarToday from '@material-ui/icons/CalendarToday';

import ArrowBackRounded from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardRounded from '@material-ui/icons/ArrowForwardIosRounded';

function Navbar(props) {
    return (
        <ul className="main-menu nav" style={{ paddingLeft: 16, justifyContent: 'flex-start' }}>
            <li>
                <Link style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }} to="#" onClick={() => {
                    if (moment(props.kalenderliturgiState.tanggal).isAfter(moment())) {
                        props.getDataKalenderLiturgi(moment(props.kalenderliturgiState.tanggal).subtract(1, 'days').format('YYYY-MM-DD'))
                    }
                }}><ArrowBackRounded /></Link>
            </li>
            <li>
                <Link style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }} to="#" onClick={() => {
                    props.gantiKalenderLiturgiState({
                        loading: true,
                        isShow_DialogPilihanTanggal: true,
                    });
                }}><CalendarToday />&nbsp;&nbsp;<span style={{ textDecoration: 'underline', textUnderlineOffset: 3 }}>{moment(props.kalenderliturgiState.data.tanggal).format('DD MMM YYYY')}</span></Link>
            </li>
            <li>
                <Link style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }} to="#" onClick={() => {
                    props.getDataKalenderLiturgi(moment(props.kalenderliturgiState.tanggal).add(1, 'days').format('YYYY-MM-DD'))
                }}><ArrowForwardRounded /></Link>
            </li>
        </ul>
    );
}

const mapStateToProps = state => {
    return { settingState: state.settingState, kalenderliturgiState: state.kalenderliturgiState }
};

export default connect(mapStateToProps, { gantiKalenderLiturgiState, getDataKalenderLiturgi })(Navbar);