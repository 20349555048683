import {
    GANTI_KALENDERLITURGI_STATE,
    GANTI_KALENDERLITURGI_TANGGAL,
    GANTI_KALENDERLITURGI_DATA,
    GANTI_KALENDERLITURGI_LOADING,
    GANTI_KALENDERLITURGI_ISSHOW_DIALOGPILIHANTANGGAL,
    GANTI_KALENDERLITURGI_ALL,
    KALENDERLITURGI_SHOWMESSAGE,
    GET_DATA_KALENDERLITURGI,
} from '../actions/types';

import { net } from '../utils/Networking';
import moment from 'moment';

const INITIAL_STATE = {
    loading: true,
    msg: 'Loading...',
    isShow_DialogPilihanTanggal: false,
    tanggal: '',
    data: {
        ket_minggu: '',
        peringatan: '',
        bacaan_pertama: '',
        judul_bacaan_pertama: '',
        teks_bacaan_pertama: '',
        mazmur_tanggapan: '',
        mazmur_refren: '',
        mazmur_teks_refren: '',
        teks_mazmur: '',
        bacaan_kedua: '',
        judul_bacaan_kedua: '',
        teks_bacaan_kedua: '',
        bait_pengantar_injil: '',
        teks_pengantar_injil: '',
        bacaan_injil: '',
        judul_bacaan_injil: '',
        teks_bacaan_injil: '',
        warna: '',
        audioURLBacaanPertama: '',
        audioURLMazmurTanggapan: '',
        audioURLBacaanKedua: '',
        audioURLBaitPengantarInjil: '',
        audioURLBacaanInjil: '',
    }
}


export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GANTI_KALENDERLITURGI_STATE:
            return {
                ...state,
                ...action.payload,
            }
        case GANTI_KALENDERLITURGI_LOADING:
            return {
                ...state,
                loading: action.payload,
            }
        case GANTI_KALENDERLITURGI_DATA:
            return {
                ...state,
                data: action.payload,
            }
        case GANTI_KALENDERLITURGI_TANGGAL:
            return {
                ...state,
                tanggal: action.payload,
            }
        case GANTI_KALENDERLITURGI_ISSHOW_DIALOGPILIHANTANGGAL:
            return {
                ...state,
                isShow_DialogPilihanTanggal: action.payload,
            }
        case GANTI_KALENDERLITURGI_ALL:
            return action.payload
        case KALENDERLITURGI_SHOWMESSAGE:
            return {
                ...state,
                msg: action.payload,
                loading: true,
                isShow_DialogPilihanTanggal: false,
            }
        case GET_DATA_KALENDERLITURGI:
            const { status_code, data_kalit, data_kalit_audio, msg } = action.payload;
            if (status_code === 1) {
                window.history.pushState('', 'Kalender Liturgi', '/kalenderliturgi/' + moment(data_kalit.tanggal).format('x'));

                return {
                    loading: false,
                    msg: 'Loading...',
                    isShow_DialogPilihanTanggal: false,
                    tanggal: moment(data_kalit.tanggal).format('YYYY-MM-DD'),
                    data: {
                        tanggal: moment(data_kalit.tanggal).format('YYYY-MM-DD'),
                        ket_minggu: data_kalit.ket_minggu,
                        peringatan: data_kalit.peringatan,
                        bacaan_pertama: data_kalit.bacaan_pertama,
                        judul_bacaan_pertama: data_kalit.judul_bacaan_pertama,
                        teks_bacaan_pertama: data_kalit.teks_bacaan_pertama,
                        mazmur_tanggapan: data_kalit.mazmur_tanggapan,
                        mazmur_refren: data_kalit.mazmur_refren,
                        mazmur_teks_refren: data_kalit.mazmur_teks_refren,
                        teks_mazmur: data_kalit.teks_mazmur,
                        bacaan_kedua: data_kalit.bacaan_kedua,
                        judul_bacaan_kedua: data_kalit.judul_bacaan_kedua,
                        teks_bacaan_kedua: data_kalit.teks_bacaan_kedua,
                        bait_pengantar_injil: data_kalit.bait_pengantar_injil,
                        teks_pengantar_injil: data_kalit.teks_pengantar_injil,
                        bacaan_injil: data_kalit.bacaan_injil,
                        judul_bacaan_injil: data_kalit.judul_bacaan_injil,
                        teks_bacaan_injil: data_kalit.teks_bacaan_injil,
                        warna: data_kalit.warna,
                        audioURLBacaanPertama: data_kalit_audio ? data_kalit_audio.bacaan_pertama : undefined,
                        audioURLMazmurTanggapan: data_kalit_audio ? data_kalit_audio.mazmur_tanggapan : undefined,
                        audioURLBacaanKedua: data_kalit_audio ? data_kalit_audio.bacaan_kedua : undefined,
                        audioURLBaitPengantarInjil: data_kalit_audio ? data_kalit_audio.bait_pengantar_injil : undefined,
                        audioURLBacaanInjil: data_kalit_audio ? data_kalit_audio.bacaan_injil : undefined,
                    }
                }
            } else {
                return {
                    loading: true,
                    isShow_DialogPilihanTanggal: false,
                    msg
                }
            }
        default:
            return state;
    }
}